
import RouterNavigate from "@/components/base/common/RouterNavigate.vue";
import {defineComponent} from "vue";
import OptOut from "@/components/base/OptOut.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "CalendarLayout",
  components: {HelpEmbed, InfoBox, OptOut, RouterNavigate},
  setup() {

  }
})
